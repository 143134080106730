// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")



import 'bootstrap'
import './src/application.scss'
import 'bootstrap-table'
import 'bootstrap-table/dist/bootstrap-table.min.css'
import 'selectize'
import 'selectize/dist/css/selectize.bootstrap3.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

$(function() {
	$('#country_id, #producer_id, #tipo, #grape').selectize()

	$('#country_id').change(function() {
		var country_id = $("#country_id option:selected").val();
		$.get( "/load/producers?country_id=" + country_id, function( data ) {
			$('#producer_id').selectize()[0].selectize.destroy();
			var options = '<option value="">Produtor</option>';	

			$.each(data, function (key, val) {
				options += '<option value="' + val.id + '">' + val.name + '</option>';
			});		
			$("#producer_id").html(options)
			$("#producer_id").selectize()
		})
	})
})

